import Cookies from 'js-cookie';

import { getMetaTag } from './utils';

export default class SiteContext {
  constructor({ page = {}, currentUser = {}, currentSite = {} }) {
    this.page = page;
    this.currentUser = currentUser;
    this.currentSite = currentSite;
    this.video = {
      has_firstplay: false,
      current_time: 0,
      duration: 0,
      last_duration_change: 0,
      last_ten_seconds_tracked: 0,
      segment_has_initialized: false,
      has_watched_quartile_one: 0,
      has_watched_quartile_two: 0,
      has_watched_quartile_three: 0,
      has_watched_quartile_complete: 0,
      is_chromecast: 0,
      is_drm: 0,
      is_trailer: 0,
      current_src: '',
      current_type: 'video',
      is_live: 0,
      is_fullscreen: 0,
    };
    this.setVideoProperty = this.setVideoProperty.bind(this);
  }

  set eventName(name) {
    this._eventName = name;
  }

  get eventName() {
    return this._eventName;
  }

  set video(video) {
    this._video = video;
  }

  get video() {
    return this._video;
  }

  get isEnabled() {
    return (
      Object.keys(this.currentSite).length > 0 &&
      Object.keys(this.currentUser).length > 0
    );
  }

  get hasPageProperties() {
    return this.page && Object.keys(this.page).length > 0;
  }

  get activeView() {
    return (
      this.page?.PROPERTIES?.VIEW_TYPE || window.location.pathname.split('/')[1]
    );
  }

  get productID() {
    return Number(
      this.page?.PROPERTIES?.PRODUCT_ID ||
        this.currentProductId ||
        this.eventProps?.id ||
        null,
    );
  }

  get collectionID() {
    return this.page?.PROPERTIES?.COLLECTION_ID || this.eventProps?.id || null;
  }

  get collectionTitle() {
    return (
      this.page?.PROPERTIES?.COLLECTION_TITLE || this.eventProps?.label || null
    );
  }

  set eventProps(properties) {
    this._eventProps = properties;
  }

  get eventProps() {
    return this._eventProps || {};
  }

  get videoID() {
    return this.page?.PROPERTIES?.VIDEO_ID || this.eventProps?.id || null;
  }

  get videoTitle() {
    return (
      this.page?.PROPERTIES?.VIDEO_TITLE ||
      this.eventProps?.label ||
      getMetaTag('og:title') ||
      null
    );
  }

  get isGDPRProtected() {
    return (
      window.location.search.indexOf('no_cookies=1') !== -1 ||
      Cookies.get('gcp') === '0'
    );
  }

  get isMadeForKids() {
    return this.currentSite.made_for_kids;
  }

  get isTrackingDisabled() {
    if (Object.keys(this.currentSite).length > 0) {
      return (
        this.isGDPRProtected ||
        (this.isMadeForKids && this.currentSite.made_for_kids_tracking_blocked)
      );
    } else {
      return this.isGDPRProtected;
    }
  }

  get isAdminVisit() {
    return window.location.pathname.indexOf('/admin') !== -1;
  }

  get isVideoView() {
    return this.activeView === 'video';
  }

  get currentProductId() {
    if (typeof window.VHX.data.product === 'object') {
      return Number(window.VHX.data.product.id);
    } else if (typeof window._vhx.product === 'object') {
      return Number(window._vhx.product.id);
    }
    return null;
  }

  get canonicalCollection() {
    return (
      this.eventProps?.canonical_collection ||
      this.page?.PROPERTIES?.CANONICAL_COLLECTION ||
      null
    );
  }

  setPageProperty(name, value) {
    const pageProperties = this.page?.PROPERTIES;
    this.page.PROPERTIES = { ...pageProperties, [name]: value };
  }

  getPageProperty(name) {
    this.page?.PROPERTIES[name];
  }

  setVideoProperty(key, value) {
    this._video[key] = value;
  }
}
