import { internalHeaders, resolveRequest, setCSRFToken } from 'js/utils/fetch';
import { OTT_WEB_METHOD } from 'js/utils/google-analytics-4-helpers';
import { getRecaptchaToken } from 'js/utils/recaptcha-helpers';
import { STANDARD_ACCOUNT_TYPE } from '~/apps/Customer/pages/checkout/constants';
import {
  calculateTaxes,
  setCurrentUser,
} from '~/apps/Customer/pages/checkout/helpers';
import 'url-search-params-polyfill';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const SELECT_PURCHASE_OPTION = 'SELECT_PURCHASE_OPTION';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRICES = 'SET_PRICES';
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const ERROR_RESET = 'ERROR_RESET';
export const ERROR_RESET_ALL = 'ERROR_RESET_ALL';
export const UPDATE_PROCESSING_PURCHASE = 'UPDATE_PROCESSING_PURCHASE';
export const SET_REGISTRATION_TYPE = 'SET_REGISTRATION_TYPE';
export const SET_SIGNUP_INFO = 'SET_SIGNUP_INFO';
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO';
export const SET_SIGNUP_INFO_VALIDITY = 'SET_SIGNUP_INFO_VALIDITY';
export const SET_GIFT_PARAMS = 'SET_GIFT_PARAMS';
export const SET_PURCHASE_TYPE_SELECTED = 'SET_PURCHASE_TYPE_SELECTED';
export const CLEAR_FREE_TRIAL = 'CLEAR_FREE_TRIAL';

const isStandardAccount = (account_type) =>
  account_type === STANDARD_ACCOUNT_TYPE;

export const changeProduct = (product) => {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCT, product });
  };
};

const shouldCalculateTaxes = (option) => {
  return (
    !option.transaction &&
    option.currency &&
    option.subtotal &&
    option.country_code &&
    !isStandardAccount(option.account_type)
  );
};

export const selectPurchaseOption = (option) => {
  return (dispatch) => {
    dispatch({ type: SELECT_PURCHASE_OPTION, option });

    if (shouldCalculateTaxes(option)) {
      calculateTaxes({
        ...option,
        tax_formatted: '$0',
        tax: 0,
      })
        .then((json) => {
          dispatch({
            type: SELECT_PURCHASE_OPTION,
            option: {
              tax_formatted: json.tax_formatted,
              tax: json.tax,
              total_formatted: json.total_formatted,
              inclusive_taxes: json.inclusive_taxes,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: SELECT_PURCHASE_OPTION,
            option: { error_message: 'could not calculate taxes' },
          });
        });
    }
  };
};

export const updateProcessingPurchase = (isProcessing) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PROCESSING_PURCHASE, isProcessing });
  };
};

// We should render the CaptchaDisclaimer on every page
// where this method is used because we are performing
// captcha verification at customer registration.
export const createCustomer = (props) => {
  return async (dispatch) => {
    let captchaToken = await getRecaptchaToken(props.recaptchaEnabled);
    const response = await fetch('/registration.json', {
      method: 'POST',
      headers: internalHeaders,
      credentials: 'same-origin',
      body: JSON.stringify({
        campaign: props.campaign,
        email: props.email,
        marketing_opt_in: props.marketing_opt_in,
        name: props.name,
        password: props.password,
        product_sku: props.productSku,
        product_type: props.productType,
        send_email: props.send_email ? 1 : 0,
        captcha_token: captchaToken,
      }),
    });

    const customer = await resolveRequest(response);

    if (customer.metadata?.authenticity_token) {
      setCSRFToken(customer.metadata.authenticity_token);
    }

    dispatch({ type: UPDATE_CUSTOMER, data: customer });

    setCurrentUser(customer);

    window.Stats.trackEvent(window.Tracking.GOOGLE_ANALYTICS_4.EVENTS.SIGN_UP, {
      user_id: customer.id,
      method: OTT_WEB_METHOD,
    });

    window.Stats.trackEvent(
      window.Tracking.EVENTS.EVENT_AUTHENTICATION_IDENTIFY_USER,
    );
  };
};

const formatCustomerThumbnail = (res) => {
  // NOTE: The customer thumbnail in create is returned as a string from API
  // Whereas for login, it is returned as an object.
  const user = res.user;
  const thumbnail =
    typeof user.thumbnail === 'object' ? user.thumbnail.small : user.thumbnail;

  return {
    ...user,
    thumbnail,
  };
};

export const login = (props) => {
  return async (dispatch) => {
    const data = JSON.stringify({
      ...props,
    });

    const response = await fetch('/login.json', {
      method: 'POST',
      headers: internalHeaders,
      credentials: 'same-origin',
      body: data,
    });

    const userInfo = formatCustomerThumbnail(await resolveRequest(response));
    dispatch({ type: UPDATE_CUSTOMER, data: userInfo });

    setCurrentUser(userInfo);

    // Track login event
    window.Stats?.trackEvent(
      window.Tracking?.EVENTS?.EVENT_AUTHENTICATION_SIGNIN_COMPLETE,
    );
  };
};

export const sendLoginLink = (email) => {
  return (dispatch) => {
    dispatch({ type: 'SEND_LOGIN_LINK' });

    return fetch(
      `/login/send_login_email?passwordless=1&email=${encodeURIComponent(
        email,
      )}`,
      {
        method: 'POST',
        headers: internalHeaders,
        credentials: 'include',
      },
    );
  };
};

export const fetchPrice = ({
  productId,
  checkoutCountryOverride = false,
  countryCode = '',
  postalCode = '',
  promoCode = '',
  donationPrice = '',
  frequency = '',
}) => {
  if (checkoutCountryOverride) {
    const searchParams = new URLSearchParams(window.location.search);
    const country = searchParams.get('country');
    if (country) {
      countryCode = country;
    }
  }

  return async (dispatch) => {
    const response = await fetch(
      `/products/${productId}/price_breakdowns?promo_code=${promoCode}&country_code=${countryCode}&postal_code=${postalCode}&donation_price=${donationPrice}&frequency=${frequency}`,
      {
        method: 'GET',
        headers: internalHeaders,
        credentials: 'include',
      },
    );

    const prices = await resolveRequest(response);
    await dispatch({ type: SET_PRICES, prices });
    return prices;
  };
};

export const errorOccurred = ({
  from,
  id = null,
  message = null,
  notificationType = null,
} = {}) => {
  return (dispatch) => {
    dispatch({
      type: ERROR_OCCURRED,
      payload: {
        from,
        id,
        message,
        notificationType,
      },
    });
  };
};

export const errorReset = ({ from } = {}) => {
  return (dispatch) => {
    dispatch({
      type: ERROR_RESET,
      payload: {
        from,
      },
    });
  };
};

export const errorResetAll = () => {
  return (dispatch) => {
    dispatch({
      type: ERROR_RESET_ALL,
    });
  };
};

export const setRegistrationType = (registrationType) => {
  return (dispatch) => {
    dispatch({
      type: SET_REGISTRATION_TYPE,
      registrationType,
    });
  };
};

export const setSignupInfo = (signupInfo) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_SIGNUP_INFO,
      signupInfo,
    });
  };
};

export const setLoginInfo = (loginInfo) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_LOGIN_INFO,
      loginInfo,
    });
  };
};

export const setGiftParams = (giftParams) => {
  return async (dispatch) => {
    await dispatch({
      type: SET_GIFT_PARAMS,
      giftParams,
    });
  };
};

export const setPurchaseTypeSelected = (purchaseTypeSelected) => {
  return (dispatch) => {
    dispatch({
      type: SET_PURCHASE_TYPE_SELECTED,
      purchaseTypeSelected,
    });
  };
};

export const clearFreeTrial = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FREE_TRIAL });
  };
};
