import { internalHeaders, resolveRequest } from 'js/utils/fetch';
import {
  STANDARD_ACCOUNT_TYPE,
  SUBSCRIPTION,
} from '~/apps/Customer/pages/checkout/constants';

const PURCHASE_TYPE_PERMANENT = 'purchase';
const PURCHASE_TYPE_TVOD_GIFT = 'tvod_gift';
const PERMANENT_PURCHASE_TYPES = [
  PURCHASE_TYPE_PERMANENT,
  PURCHASE_TYPE_TVOD_GIFT,
];

export const TRANSACTION_PATH = '/transactions';
export const DIRECT_TRANSACTION_PATH = '/direct_transactions';

const isStandardAccount = (accountType: string) =>
  accountType === STANDARD_ACCOUNT_TYPE;

const chargePath = (accountType: string) => {
  return isStandardAccount(accountType)
    ? DIRECT_TRANSACTION_PATH
    : TRANSACTION_PATH;
};

type FetchTaxesProps = {
  currency: string;
  subtotal: number;
  discount_value: number | null;
  country_code: string;
  postal_code: string;
  has_recorded_content: boolean;
  frequency: string;
};

export const calculateTaxes = async (props: FetchTaxesProps) => {
  const response = await fetch(`/checkout/taxes.json`, {
    method: 'POST',
    headers: internalHeaders,
    credentials: 'same-origin',
    body: JSON.stringify({
      currency: props.currency,
      subtotal: props.subtotal - (props.discount_value || 0),
      country_code: props.country_code,
      postal_code: props.postal_code,
      has_recorded_content: props.has_recorded_content,
      purchase_type: PERMANENT_PURCHASE_TYPES.includes(props.frequency)
        ? PURCHASE_TYPE_PERMANENT
        : '',
    }),
  });
  return await response.json();
};

type GatewayProps = {
  transactionId: string;
  accountType: string;
  verificationKey?: string;
};

const noCacheHeaders = {
  ...internalHeaders,
  'Cache-Control': 'no-cache, no-store',
};

export const fetchGatewayResponse = async (props: GatewayProps) => {
  const baseUrl = chargePath(props.accountType);

  const resp = await fetch(`${baseUrl}/${props.transactionId}/retrieve`, {
    method: 'GET',
    headers: noCacheHeaders,
    credentials: 'include',
  });
  return await resolveRequest(resp);
};

export const confirmPaymentIntent = async (props: GatewayProps) => {
  const baseUrl = chargePath(props.accountType);

  const resp = await fetch(`${baseUrl}/${props.transactionId}/confirm`, {
    method: 'PUT',
    headers: noCacheHeaders,
    credentials: 'include',
  });
  return await resolveRequest(resp);
};

export const fetchPurchaseStatus = async (props: GatewayProps) => {
  const baseUrl = chargePath(props.accountType);
  const url = props?.verificationKey
    ? `${baseUrl}/${props.transactionId}?verification_key=${props.verificationKey}`
    : `${baseUrl}/${props.transactionId}`;

  const resp = await fetch(url, {
    method: 'GET',
    headers: noCacheHeaders,
    credentials: 'include',
  });
  return await resolveRequest(resp);
};

type CreateProps = {
  accountType: string;
  productSku: string;
  promoCode: string;
  isRental?: boolean;
};

type PaidCreateProps = CreateProps & {
  stripeResponse: any;
  overrideCountry: boolean;
  donationPrice: number;
  campaign?: string | null;
};

const createTransaction = async (accountType: string, body: any) => {
  return await fetch(chargePath(accountType), {
    method: 'POST',
    headers: internalHeaders,
    credentials: 'same-origin',
    body: JSON.stringify(body),
  });
};

export const createFreeTvodTransaction = async (props: CreateProps) => {
  return await createTransaction(props.accountType, {
    product_sku: props.productSku,
    code: props.promoCode,
    is_rental: props.isRental,
    card: { name: '', country_code: '' },
  });
};

type StripeToken = {
  id: string;
  card?: {
    country: string | null;
    address_zip: string | null;
    name: string | null;
  };
};

type StripePaymentMethod = {
  id: string;
  card?: {
    country?: string | null;
    address_zip?: string | null;
    name?: string | null;
  };
  billing_details?: {
    address?: {
      postal_code?: string | null;
    };
    name?: string | null;
  };
};

type StripeResponse = {
  token?: StripeToken;
  paymentMethod?: StripePaymentMethod;
};

function cardParams(stripeResponse: StripeResponse, overrideCountry: boolean) {
  let countryCode =
    stripeResponse.token?.card?.country ||
    stripeResponse.paymentMethod?.card?.country;

  if (overrideCountry) {
    // TODO: This should be moved outside of this scope
    const searchParams = new URLSearchParams(window.location.search);
    const country = searchParams.get('country');
    if (country) {
      countryCode = country;
    }
  }

  return {
    payment_method_id: stripeResponse.paymentMethod?.id,
    token: stripeResponse.token?.id,
    country_code: countryCode,
    postal_code:
      stripeResponse.token?.card?.address_zip ||
      stripeResponse.paymentMethod?.billing_details?.address?.postal_code,
    name:
      stripeResponse.token?.card?.name ||
      stripeResponse.paymentMethod?.billing_details?.name,
  };
}

export const createTvodTransaction = async (props: PaidCreateProps) => {
  return await createTransaction(props.accountType, {
    card: cardParams(props.stripeResponse, props.overrideCountry),
    product_sku: props.productSku,
    code: props.promoCode,
    is_rental: props.isRental,
    donation_price: props.donationPrice,
    campaign: props.campaign,
  });
};

type CreateSubscriptionProps = PaidCreateProps & {
  frequency: string;
  forceCharge: boolean;
  attribution: string;
};

export const createSubscriptionTransaction = async (
  props: CreateSubscriptionProps,
) => {
  return await createTransaction(props.accountType, {
    card: cardParams(props.stripeResponse, props.overrideCountry),
    frequency: props.frequency,
    product_type: SUBSCRIPTION,
    product_sku: props.productSku,
    code: props.promoCode,
    campaign: props.campaign,
    force_charge: props.forceCharge ? '1' : undefined,
    attribution: props.attribution,
  });
};

type CreateGiftProps = CreateSubscriptionProps & {
  type: string;
  giftParams: {
    senderEmail: string;
    recipientEmail: string;
    message: string;
    sendAt: Date;
  };
  durationMonths: number;
};

export const createGiftTransaction = async (props: CreateGiftProps) => {
  return await createTransaction(props.accountType, {
    user: { email: props.giftParams.senderEmail },
    product_sku: props.productSku,
    product_type: props.type,
    frequency: props.frequency,
    card: cardParams(props.stripeResponse, props.overrideCountry),
    is_rental: props.isRental,
    campaign: props.campaign,
    attribution: props.attribution,
    gift: {
      emails: [props.giftParams.recipientEmail],
      message: props.giftParams.message,
      send_at: props.giftParams.sendAt,
      duration_months: props.durationMonths,
    },
  });
};

type UpdatePaymentMethodProps = {
  id: string;
  postalCode: string;
  user?: {
    email: string;
  };
};

export const updatePaymentMethod = async (props: UpdatePaymentMethodProps) => {
  const response = await fetch('/payment_method/update', {
    method: 'PATCH',
    headers: internalHeaders,
    credentials: 'same-origin',
    body: JSON.stringify({
      id: props.id,
      postal_code: props.postalCode,
      user: props.user,
    }),
  });
  return await resolveRequest(response);
};
